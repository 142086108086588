import React from 'react';
import Card from './Card/Card';
import MainPic from '../assets/images/mainpic.png'
import Impact2 from '../assets/images/impact2.png'
import Impact3 from '../assets/images/impact3.png'
import Impact4 from '../assets/images/impact4.png'
import logo from '../assets/images/logo.jpg';

// const dummyData = [
//   {
//     title: 'Card 1',
//     image: MainPic,
//     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio.'
//   },
//   {
//     title: 'Card 2',
//     image: 'https://via.placeholder.com/400x200',
//     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio.'
//   },
//   {
//     title: 'Card 3',
//     image: 'https://via.placeholder.com/400x200',
//     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio.'
//   },
//   {
//     title: 'Card 4',
//     image: 'https://via.placeholder.com/400x200',
//     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio.'
//   },
//   {
//     title: 'Card 5',
//     image: 'https://via.placeholder.com/400x200',
//     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio.'
//   },
//   {
//     title: 'Card 6',
//     image: 'https://via.placeholder.com/400x200',
//     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio.'
//   }
// ];

const Project = () => {
  return (
<section class="container mx-auto px-8 py-8 lg:py-8">
  <h2 class="block antialiased tracking-normal font-sans text-4xl font-semibold leading-[1.3] text-blue-gray-900 !text-3xl !leading-snug lg:!text-4xl">Impacts</h2>
  <p class="block antialiased font-sans text-xl font-normal leading-relaxed text-inherit mt-2 w-full font-normal !text-gray-500 lg:w-5/12">Read about our latest impacts to the society.</p>
  <div class="mt-10 grid grid-cols-1 gap-10 lg:grid-cols-2">
    <div class="relative flex flex-col bg-clip-border rounded-xl bg-transparent text-gray-700 shadow-md relative grid min-h-[30rem] items-end overflow-hidden rounded-xl">
      
      <img alt="..." className='h-full w-full lg:object-cover lg:object-center' src={MainPic}/>
      <div class="p-6 relative bg-blue-600 flex flex-col justify-end">
        <p class="block antialiased font-sans text-base font-light leading-relaxed text-white my-2 font-normal">The Director WARHF (Dr. Ann Mwangi) and people living with disabilities (PLWD) at Ruiru sub-county offices on a courtesy call</p>
      </div>
    </div>
    <div class="relative flex flex-col bg-clip-border rounded-xl bg-transparent text-gray-700 shadow-md relative grid min-h-[30rem] items-end overflow-hidden rounded-xl">
      <img alt="..." className='h-full w-full lg:object-cover lg:object-center' src={Impact2}/>
      <div class="p-6 relative bg-blue-600 flex flex-col justify-end">
        <p class="block antialiased font-sans text-base font-light leading-relaxed text-white my-2 font-normal">The Director WARHF (Dr. Ann Mwangi) with one of the beneficiary of the stalls constructed through contributions by JKUAT students in enhancement of economic empowerment to the (PLWDs)</p>
      </div>
    </div>
  </div>
  <div class="mt-10 grid grid-cols-1 gap-10 lg:grid-cols-2">
  <div class="relative flex flex-col bg-clip-border rounded-xl bg-transparent text-gray-700 shadow-md grid min-h-[30rem] items-end overflow-hidden rounded-xl">
    <img alt="..." src={Impact3} class="w-full h-full lg:object-cover lg:object-center" />
    <div class="p-6 relative bg-blue-600 flex flex-col justify-end w-full">
      <p class="block antialiased font-sans text-base font-light leading-relaxed text-white my-2 font-normal">
        Teenage girls receive sanitary towels from “save the teenage girl initiative” under WARHF at Kangari catholic church in Murang’a county.
      </p>
    </div>
    </div>

    <div class="relative flex flex-col bg-clip-border rounded-xl bg-transparent text-gray-700 shadow-md relative grid min-h-[30rem] items-end overflow-hidden rounded-xl">
      <img alt="..." src={Impact4} class="w-full h-full lg:object-cover lg:object-center"/>
      <div class="p-6 relative bg-blue-600 flex flex-col justify-end">
        <p class="block antialiased font-sans text-base font-light leading-relaxed text-white my-2 font-normal">Teenage girls receive sanitary towels from “save the teenage girl initiative” under WARHF at Kangari catholic church in Murang’a county.</p>
      </div>
    </div>
  </div>
</section>
  );
};

export default Project;
