import React from 'react';
import MainPic from '../assets/images/homepic.png';
import '../index.css';

const Home = () => {
  return (
<section className="relative bg-blueGray-50">
<div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
    <div
        className="absolute top-0 w-full h-full"
    >
        <span id="blackOverlay" className="w-full h-full absolute opacity-50 bg-black"></span>
        <img alt="..." className='h-full w-full object-cover object-center' src={MainPic}/>

    </div>
    <div className="relative z-10 container flex items-center justify-center mx-auto h-screen">
        <div className="w-full lg:w-6/12 px-4 text-center">
            <div className="py-12">
                <h1 className="text-white font-semibold text-5xl">
                    WOMEN ACCESS TO RURAL HEALTH FOUNDATION<br/> (WARHF)
                </h1>
            </div>
        </div>
    </div>
</div>


      <section className="pb-10 bg-blueGray-200 -mt-24">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap">
            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex-auto">
                  <h6 className="text-xl font-semibold">Mission Statement</h6>
                  <p className="mt-2 mb-4 text-blueGray-500">
                  At WARHF, our mission is to ensure that vulnerable and underserved communities receive quality, accessible, and affordable health services. 
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex-auto">
                  <h6 className="text-xl font-semibold">Non-Government Organization</h6>
                  <p className="mt-2 mb-4 text-blueGray-500">
                  WARHF is a non-profit NGO registered under the act
                  </p>
                </div>
              </div>
            </div>
            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex-auto">
                  <h6 className="text-xl font-semibold">Vision for Healthcare</h6>
                  <p className="mt-2 mb-4 text-blueGray-500">
                  WARHF is dedicated to transforming healthcare access for underserved communities, ensuring that quality, affordable services are within everyone's reach.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Home;
