import React, { useState,useEffect,useRef } from 'react';
import logo from '../assets/images/logo.jpg';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="sticky top-0 w-full bg-white text-blue-600 z-50">
      <nav className="container mx-auto flex justify-between items-center p-2">
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="h-40 mr-4" />
          <div className="text-lg hidden md:block font-bold">W.A.R.H.F</div>
        </div>
        <div className="block lg:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            {isOpen ? (
              <XIcon className="h-8 w-8 text-blue-600" />
            ) : (
              <MenuIcon className="h-8 w-8 text-blue-600" />
            )}
          </button>
        </div>
        <ul className="hidden lg:flex lg:space-x-4">
          <li><NavLink to="/" className={({ isActive }) => `block p-4 text-blue-600 ${isActive ? 'bg-red-500 text-white' : 'hover:bg-red-500 text-blue-600'}`}>Home</NavLink></li>
          <li><NavLink to="/impact" className={({ isActive }) => `block p-4 text-blue-600 ${isActive ? 'bg-red-500 text-white' : 'hover:bg-red-500 text-blue-600'}`}>Impacts</NavLink></li>
          <li><NavLink to="/message" className={({ isActive }) => `block p-4 text-blue-600 ${isActive ? 'bg-red-500 text-white' : 'hover:bg-red-500 text-blue-600'}`}>Message</NavLink></li>
          <li><NavLink to="/about" className={({ isActive }) => `block p-4 text-blue-600 ${isActive ? 'bg-red-500 text-white' : 'hover:bg-red-500 text-blue-600'}`}>About Us</NavLink></li>
          <li>
          <a
              className="block p-4 font-semibold bg-transparent rounded-lg hover:text-white hover:bg-red-500   focus:outline-none focus:shadow-outline"
              href="https://dpeeik.warhf.org/"
              target='_blank'
              
            >
              DPEEIK
            </a></li>
            <li>
            <a
              className="block p-4 font-semibold bg-transparent rounded-lg hover:text-white hover:bg-red-500   focus:outline-none focus:shadow-outline"
              href="https://stegi.warhf.org/"
            >
              STeGI
            </a>
          </li>
        </ul>
      </nav>
      <div className={`fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden transition-opacity ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} onClick={toggleMenu}></div>
      <div className={`fixed inset-y-0 left-0 bg-blue-600 z-50 w-64 transform transition-transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
        <div className="flex justify-between items-center p-4 border-b border-blue-700">
          <div className="text-lg font-bold text-white">W.A.R.H.F</div>
          <button onClick={toggleMenu} className="focus:outline-none">
            <XIcon className="h-8 w-8 text-white" />
          </button>
        </div>
        <ul className="flex flex-col space-y-4 p-4">
          <li><NavLink to="/" className="block p-4 text-white hover:bg-red-500">Home</NavLink></li>
          <li><NavLink to="/impact" className="block p-4 text-white hover:bg-red-500">Impacts</NavLink></li>
          <li><NavLink to="/message" className="block p-4 text-white hover:bg-red-500">Message</NavLink></li>
          <li><NavLink to="/about" className="block p-4 text-white hover:bg-red-500">About Us</NavLink></li>
          <li>
          <a
              className="block p-4 font-semibold bg-transparent rounded-lg hover:text-white hover:bg-red-500 text-white focus:outline-none focus:shadow-outline"
              href="https://dpeeik.warhf.org/"
              target='_blank'
              
            >
              DPEEIK
            </a></li>
            <li>
            <a
              className="block p-4 font-semibold bg-transparent rounded-lg hover:text-white hover:bg-red-500 text-white  focus:outline-none focus:shadow-outline"
              href="https://stegi.warhf.org/"
            >
              STeGI
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
