import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Project from './components/Project';
import Sponsor from './components/Sponsor';
import Donate from './components/Donate';
import Footer from './components/Footer';
import './index.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className=''>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/message" element={<Contact />} />
          <Route path="/impact" element={<Project />} />
        </Routes>
        </div>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
