import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.jpg';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Footer = () => {
  return (
    <footer className="bg-blue-600 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          {/* Company Info */}
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <img src={logo} alt="Logo" className="h-20 mr-4" />
          <div className="text-lg font-bold">W.A.R.H.F</div>
          </div>
          {/* Navigation Links */}
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h5 className="text-xl font-bold mb-2">Quick Links</h5>
            <ul className="list-none">
              <li>
                <Link to="/" className="hover:text-red-500">Home</Link>
              </li>
              <li>
                <Link to="/about" className="hover:text-red-500">About Us</Link>
              </li>
              <li>
                <Link to="/impact" className="hover:text-red-500">Impact</Link>
              </li>
              <li>
                <Link to="/message" className="hover:text-red-500">Message</Link>
              </li>
            </ul>
          </div>
          {/* Social Media Links */}
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h5 className="text-xl font-bold mb-2">Follow Us</h5>
          <div className="flex space-x-4 mb-4">
          <a  target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a>
          <a  target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
        </div>
          </div>
        </div>
        <div className="text-center mt-8">
          <p className="text-gray-200">&copy; 2024 WARHF. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
