import React from 'react';
import logo from '../assets/images/logo.jpg';

const About = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">About Us</h1>
      <section class="relative pt-16 bg-blueGray-50">
<div class="container mx-auto">
  <div class="lg:flex items-center block">
    <div class="w-full md:w-10/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-78">
      <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-500">
        <img alt="..." src={logo}/>
        <blockquote class="relative bg-blue-600 p-8 mb-4">

          <h4 class="text-xl font-bold text-white">
          Women Access To Rural Health Foundation
          </h4>
          <p class="text-md font-light mt-2 text-white">
          WARHF is a non-profit NGO registered under the act, established based on four key pillars
          </p>
        </blockquote>
      </div>
    </div>

    <div class="w-full lg:w-10/12 px-4">
      <div class="flex flex-wrap">
        <div class="w-full mx-auto grid gap-6 md:w-3/4 lg:w-full lg:grid-cols-2">
          
            <div class="bg-white h-50 rounded-2xl shadow-xl px-8 py-6 sm:px-6 lg:px-8">
             
              <h6 class="text-xl mb-1 font-semibold">Pillar One</h6>
              <p class="mb-4 text-blueGray-500">
              To improve provision of affordable, accessible and quality comprehensive package of basic health care services to the vulnerable underserved communities including women and children, people living with disabilities, youths the elderly youths at house hold/ boma levels though employing innovative novel models at primary healthcare levels. 

              </p>
            
            </div>
          
            <div class="bg-white h-50 rounded-2xl shadow-xl px-8 py-6 sm:px-6 lg:px-8">
              
              <h6 class="text-xl mb-1 font-semibold">
              Pillar Two
              </h6>
              <p class="mb-4 text-blueGray-500">
              Seek to find solutions to the unemployment challenges facing youths plus the social economic hardships faced by women and people living with disabilities (PLWDs) through developing novel models in entrepreneurship plus other economic empowerment solutions.
              </p>
            </div>
          
        </div>
        <div class="w-full mt-5 mx-auto grid gap-6 md:w-3/4 lg:w-full lg:grid-cols-2">
          
            <div class="bg-white rounded-2xl shadow-xl px-8 py-6 sm:px-6 lg:px-8">
              
              <h6 class="text-xl mb-1 font-semibold">Pillar Three</h6>
              <p class="mb-4 text-blueGray-500">
              To promote capacity building programs in matters of education, health, live skills among youths, women, and teenagers through peer education model and student exchange programmes
              </p>
            </div>
          
          
            <div class="bg-white rounded-2xl shadow-xl px-8 py-6 sm:px-6 lg:px-8">
              
              <h6 class="text-xl mb-1 font-semibold">Pillar Four</h6>
              <p class="mb-4 text-blueGray-500">
              Seek to find solutions to matters of mental health that includes; combating drug substance abuse among the youths at homes, in schools and colleges as well as teenage pregnancy
              </p>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</section>
<hr className='my-10'></hr>
<div class="container mx-auto mt-5 text-center">
    <h2 class="block antialiased tracking-normal font-sans text-4xl font-semibold leading-[1.3] text-blue-gray-900 mb-4">Mission</h2>
    <hr className='my-5 bg-black'></hr>
    <p class="block antialiased font-sans text-base leading-relaxed text-inherit mb-8 font-normal !text-gray-500">The mission of WARHF is to promote access to quality, accessible and affordable health services to the vulnerable underserved communities.</p>
  </div>
    </div>
  );
};

export default About;
