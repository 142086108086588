import React,{useState} from 'react';

const Contact = () => {
  const [testimonialActive, setTestimonialActive] = useState(2);

  const testimonials = [
    {
      text: "People who live in rural underserved rural communities are underprivileged to access quality healthcare services. This is because, they never seek treatment even when they absolutely need it, simply because they cannot afford it. This goes against the right of every individual to get quality healthcare in attainment of universal health coverage.As the founding director of WARHF, we endeavor to reach those people in underserved rural communities to enhance improvement of healthcare services especially women, children, youths and people with disabilities (PWDs).",
      author: "Dr. Ann Wairimu Mwangi",
      position: "FOUNDING DIRECTOR, WARHF",
    },
    {
      text: "Women access to rural health foundation (WARHF) is an independent non profit organization that was established in 2017. WARHF is fast thriving to excellence in providing quality, accessible andaffordable health care services to vulnerable and underserved rural communities. In recent past, new model has been established in keeping with WARHF adherence and dedication in seeking to find solutions and be ready for the future to effectively and proficiently respond to health challenges as well as youth unemployment,capacity building among others. WARHF is dedicated to nurturing culture of quality, affordable and accessible health care services to be within everyone reach. We welcome you all to the Women Access to Rural Health Foundation (WARHF), a place where health matters.",
      author: "Caroline Chepng’eno Sigei",
      position: "TREASURER, WARHF",
    }
  ];
  return (
    <div className="my-10 md:my-24 container mx-auto flex flex-col md:flex-row shadow-sm overflow-hidden">
      <div className="relative w-full py-2 md:py-24 bg-indigo-700 md:w-1/2 flex flex-col items-center justify-center">
        <div className="absolute top-0 left-0 z-10 grid-indigo w-16 h-16 md:w-40 md:h-40 md:ml-20 md:mt-24"></div>
        <div className="relative text-2xl md:text-5xl py-2 px-6 md:py-6 md:px-1 md:w-64 md:mx-auto text-indigo-100 font-semibold leading-tight tracking-tight mb-0 z-20">
          <span className="md:block">Message</span>
          <span className="md-block"> From</span>
          <span className="block">Our Representatives</span>
        </div>
      </div>
      <div className="bg-gray-100 md:w-1/2">
        <div className="flex flex-col h-full relative">
          <div className="absolute top-0 left-0 mt-3 ml-4 md:mt-5 md:ml-12">
            <svg xmlns="http://www.w3.org/2000/svg" className="text-indigo-200 fill-current w-12 h-12 md:w-16 md:h-16" viewBox="0 0 24 24"><path d="M6.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L9.758 4.03c0 0-.218.052-.597.144C8.97 4.222 8.737 4.278 8.472 4.345c-.271.05-.56.187-.882.312C7.272 4.799 6.904 4.895 6.562 5.123c-.344.218-.741.4-1.091.692C5.132 6.116 4.723 6.377 4.421 6.76c-.33.358-.656.734-.909 1.162C3.219 8.33 3.02 8.778 2.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C2.535 17.474 4.338 19 6.5 19c2.485 0 4.5-2.015 4.5-4.5S8.985 10 6.5 10zM17.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L20.758 4.03c0 0-.218.052-.597.144-.191.048-.424.104-.689.171-.271.05-.56.187-.882.312-.317.143-.686.238-1.028.467-.344.218-.741.4-1.091.692-.339.301-.748.562-1.05.944-.33.358-.656.734-.909 1.162C14.219 8.33 14.02 8.778 13.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C13.535 17.474 15.338 19 17.5 19c2.485 0 4.5-2.015 4.5-4.5S19.985 10 17.5 10z"/></svg>
          </div>
          <div className="h-full relative z-10">
            {testimonials.map((testimonial, index) => (
              <div key={index} className={testimonialActive === index + 1 ? 'block' : 'hidden'}>
                <p className="text-gray-600 serif font-normal italic px-6 py-6 md:px-16 md:py-10 text-xl md:text-2xl">
                  {testimonial.text}
                </p>
              </div>
            ))}
          </div>
          <div className="flex my-4 justify-center items-center">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => setTestimonialActive(index + 1)}
                className={`text-center font-bold shadow-xs focus:outline-none focus:shadow-outline inline-block rounded-full mx-2 ${testimonialActive === index + 1 ? 'h-16 w-16 opacity-100 bg-indigo-600 text-white' : 'h-12 w-12 opacity-25 bg-indigo-300 text-gray-600'}`}
              >
                {index === 0 ? 'AW' : index === 1 ? 'CC' : 'JW'}
              </button>
            ))}
          </div>
          <div className="flex justify-center px-6 pt-2 pb-6 md:py-6">
            {testimonials.map((testimonial, index) => (
              <div key={index} className={testimonialActive === index + 1 ? 'block' : 'hidden'}>
                <div className="text-center">
                  <h2 className="text-sm md:text-base font-bold text-gray-700 leading-tight">{testimonial.author}</h2>
                  <small className="text-gray-500 text-xs md:text-sm truncate">{testimonial.position}</small>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
